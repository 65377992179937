export default (supervisionEndTime) => ({
  remainingTimeInSeconds: 0,
  supervisionEndTime: new Date(supervisionEndTime),

  init() {
    this.updateRemainingTime()

    if (this.remainingTimeInSeconds <= 0) {
      this.courseTimeOut()
    } else {
      setTimeout(() => {
        this.courseTimeOut()
      }, this.remainingTimeInSeconds * 1000)
    }

    this.setupAlerts()
    this.startCountdown()
  },

  addPad(num) {
    return num < 0 ? '00' : String(num).padStart(2, '0')
  },

  courseTimeOut() {
    openAlertModal(1, I18n.attention, I18n.bye_online_lesson)
    window.dispatchEvent(new CustomEvent('pauseCourseVideo'))

    setTimeout(() => window.location.href = '/student', 3000)
  },

  getFormattedRemainingTime() {
    const hours = Math.floor((this.remainingTimeInSeconds % (60 * 60 * 24)) / (60 * 60))
    const minutes = Math.floor((this.remainingTimeInSeconds % (60 * 60)) / 60)
    const seconds = Math.floor(this.remainingTimeInSeconds % 60)
    const formattedHours = this.addPad(hours)
    const formattedMinutes = this.addPad(minutes)
    const formattedSeconds = this.addPad(seconds)

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  },

  setupAlerts() {
    const alertTimes = [600, 300, 60]
    alertTimes.forEach((alertTime) => {
      if (this.remainingTimeInSeconds - alertTime <= 0) return
      setTimeout(
        () => {
          openAlertModal(1, I18n.attention, alertTime / 60 + ' ' + I18n.minutes_left)
        },
        (this.remainingTimeInSeconds - alertTime) * 1000
      )
    })
  },

  startCountdown() {
    const countdownInterval = setInterval(() => {
      this.updateRemainingTime()

      if (this.remainingTimeInSeconds <= 0) {
        clearInterval(countdownInterval)
      }
    }, 1000)
  },

  updateRemainingTime() {
    this.remainingTimeInSeconds = (this.supervisionEndTime - new Date()) / 1000
  },
})
